export default {
  date_format: [
    { value: 'DMY', text: 'DD/MM/YYYY' },
    { value: 'MDY', text: 'MM/DD/YYYY' },
    { value: 'YMD', text: 'YYYY/MM/DD' },
  ],

  time_format: [
    { value: '24', text: '23:59' },
    { value: '12', text: '11:59 PM' },
  ],

  currency_format: [
    {
      text: 'EUR',
      sign: '€',
      delimiter: '.',
      separator: ',',
    },
    {
      text: 'USD',
      sign: '$',
      delimiter: ',',
      separator: '.',
    },
    {
      text: 'AUD',
      sign: '$',
      delimiter: ' ',
      separator: '.',
    },
    {
      text: 'CAD',
      sign: '$',
      delimiter: ',',
      separator: '.',
    },
    {
      text: 'CHF',
      sign: 'CHF',
      delimiter: '.',
      separator: ',',
    },
    {
      text: 'CRC',
      sign: '₡',
      delimiter: '.',
      separator: ',',
    },
    {
      text: 'CZK',
      sign: 'Kč',
      delimiter: '.',
      separator: ',',
    },
    {
      text: 'DKK',
      sign: 'kr',
      delimiter: '.',
      separator: ',',
    },
    {
      text: 'GBP',
      sign: '£',
      delimiter: ',',
      separator: '.',
    },
    {
      text: 'ILS',
      sign: '₪',
      delimiter: ',',
      separator: '.',
    },
    {
      text: 'JPY',
      sign: '¥',
      delimiter: ',',
      separator: '',
    },
    {
      text: 'MXN',
      sign: '$',
      delimiter: ',',
      separator: '.',
    },
    {
      text: 'NOK',
      sign: 'kr',
      delimiter: '.',
      separator: ',',
    },
    {
      text: 'PEN',
      sign: 'S/.',
      delimiter: '.',
      separator: ',',
    },
    {
      text: 'PHP',
      sign: '₱',
      delimiter: ',',
      separator: '.',
    },
    {
      text: 'SEK',
      sign: 'kr',
      delimiter: ' ',
      separator: ',',
    },
  ],
  distance_unit_format: [
    { unit: 'meter', abbreviation: 'm' },
    { unit: 'feet', abbreviation: 'ft' },
  ],
}
